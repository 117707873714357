import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Constants from "../utils/Constants";
import { getAccessToken } from "../utils/Auth";
import Notification from "./Notification"
import { epochSeconds, formatDate, getDetail, getFileUrls, isHidden } from "../utils/Functions";
import ColumnsDialog from "./ColumnsDialog";
import SitesDialog from "./SitesDialog";
import DeleteDialog from "./DeleteDialog";
import { Paper, Stack, IconButton } from '@mui/material'
import { Box, Select, MenuItem, Typography, makeStyles } from '@material-ui/core'
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import DeployDialog from "./DeployDialog";
import {toast} from "react-toastify";


let http_str = "https://"
let base_url = http_str.concat(Constants.apiHost, '.', Constants.domainName,'/api/v1/sites')


const useStyles = makeStyles(theme => ({
    outer_div: {
        minHeight: '100vh',
    },
    card: {
        backgroundColor: '#424242',
        color: '#EEEEEE',
    },
    itemBar: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#222222',
    },
    pageBar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        backgroundColor: '#222222',
        color: '#AAAAAA',
    },
    tableComponent: {
        borderStyle: 'solid',
        borderColor: '#000',
        borderRadius: '10px',
        borderSpacing: '0px',
        borderRightWidth: '0px',
        borderTopWidth: '1px',
        borderBottomWidth: '0px',
        borderLeftWidth: '0px',
        // backgroundColor: '#222222' // setting this breaks row highlighting
    },
    tableCell: {
        paddingLeft: '12px',
    },
    clickableTableCell: {
        paddingLeft: '12px',
        cursor: 'pointer',
    },
    dialog: {
        backgroundColor: '#333333',
        color: '#CCCCCC',
        margin: '10',
    },
    dialogTitleBar: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
    },
    dialogConfirm: {
        backgroundColor: '#149616',
        color: '#CCCCCC',
    },
    dialogNeutral: {
        backgroundColor: '#4e54bf',
        color: '#CCCCCC',
    },
    dialogWarning: {
        backgroundColor: '#d42842',
        color: '#CCCCCC',
    },
    primaryColor: {
        color: '#000',
        backgroundColor: '#111'
    },
    whiteColor: {
        color: "#EEE",
    },
    hide: {
        display: 'none',
    },
    spaced: {
        margin: '10px',
    }
}))

let headers = [
    { field: 'pk', headerName: 'ID', width: 350, hidden: true },
    { field: 'site_name', headerName: 'Name', width: 0, hidden: false },
    { field: 'site_type', headerName: 'Type', width: 100, hidden: false },
    { field: 'aws_account_id', headerName: 'Account ID', width: 150, hidden: false },
    { field: 'ref_count', headerName: 'Used', width: 50, hidden: false },
    { field: 'created', headerName: 'Created', width: 200, hidden: false},
    { field: 'modified', headerName: 'Modified', width: 200, hidden: false},
    { field: 'edit', headerName: 'Edit', width: 50, hidden: false },
    { field: 'deploy', headerName: 'Deploy', width: 50, hidden: false },
    { field: 'delete', headerName: 'Delete', width: 50, hidden: false },
  ]

const toastOptions = {
    position: "bottom-right",
    autoClose: false,
    hideProgressBar: false,
    pauseOnHover: true,
    draggable: true
}

const getBlankFormData = () => {
    return ({
        pk: '',
        site_name: '',
        site_type: 'onprem',
        aws_account_id: '',
        policies_deployed: false,
    })
}

export default function Sites() {
    const classes = useStyles()
    const [items, setItems] = useState([])
    const [selectedItem, setSelectedItem] = useState(null)
    const [selectedRow, setSelectedRow] = useState(null)
    const [columnsMode, setColumnsMode] = useState(false)
    const [addMode, setAddMode] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [deployMode, setDeployMode] = useState(false)
    const [deployHref, setDeployHref] = useState('')
    const [deployCfn, setDeployCfn] = useState('')
    const [deleteMode, setDeleteMode] = useState(false)
    const [limit, setLimit] = React.useState(10)
    const [pages, setPages] = useState([0])
    const [pageIndex, setPageIndex] = useState(0)
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [requiresSave, setRequiresSave] = useState(false)
    const [formData, setFormData] = useState(getBlankFormData())

    const getItems = async () => {
        let url = base_url
        if (pageIndex !== 0) {
            console.log('fetch page: ' + pages[pageIndex])
            url = url + '?limit=' + limit + '&nextPageKey=' + pages[pageIndex]
        }
        else {
            console.log('fetch page: 0')
            url = url + '?limit=' + limit
        }
        const http_headers = { "Content-Type": "application/json","Authorization": await getAccessToken() }
        console.log('url: ' + url)
        await axios
            .get(url, { headers: http_headers })
            .then(res => {
                if (pageIndex === pages.length - 1) {
                    let npk = res.data['nextPageKey']
                    if (npk) {
                        let tmp_arr = pages
                        tmp_arr.push(npk)
                        setPages(tmp_arr)
                    }
                }
                const items = res.data['sites'];
                setItems(items)
            })
            .catch(err => {
                if (err.response) {
                  console.log('bad response from server: ' + err.response)
                } else if (err.request) {
                  console.log('no response from server: ' + err.request)
                } else {
                  console.log(err)
                }
                Notification(`Error while fetching ${url}. ${err}`, 'error');
            })
    }

    const addItem = async (fields) => {
        let url = base_url
        const http_headers = { 'Content-Type': 'application/json','Authorization': await getAccessToken() }
        let params = {
            'site_name': fields.site_name,
            'site_type': fields.site_type,
            'aws_account_id': fields.aws_account_id,
            'policies_deployed': fields.policies_deployed,
        }
        const toastId = toast.loading('adding Site...', toastOptions)
        await axios
            .post(url, { 'site': params },{ headers: http_headers })
            .then(res => {
                console.log(res.data);
                // Add the data submitted back to the local table
                params.pk = res.data['pk']
                const epoch = epochSeconds()
                params.created = epoch
                params.modified = epoch
                setItems(items.concat([params]))
                setRequiresSave(false)
                toast.update(toastId, {render: `${res.data['msg']}`, type: "success", isLoading: false, autoClose: 2000})
            })
            .catch(err => {
                if (err.response) {
                  console.log('bad response from server: ' + err.response)
                } else if (err.request) {
                  console.log('no response from server: ' + err)
                } else {
                  console.log(err)
                }
                toast.update(toastId, {render: `Error adding Site: ${err}`, type: "error", isLoading: false})
            })
    }

    const editItem = async (fields) => {
        let url = base_url + "/" + fields.pk
        const http_headers = { 'Content-Type': 'application/json','Authorization': await getAccessToken() }
        let params = {
            'pk': fields.pk,
            'site_name': fields.site_name,
            'site_type': fields.site_type,
            'aws_account_id': fields.aws_account_id,
            'policies_deployed': fields.policies_deployed
        }
        const toastId = toast.loading('saving Site...', toastOptions)
        await axios
            .put(url, { 'site': params },{ headers: http_headers })
            .then(res => {
                // Add the data submitted back to the local table
                const newItems = [...items]
                let arr_index = items.findIndex(itm => itm.pk === formData.pk)
                if (arr_index !== -1) {
                    newItems[arr_index] = formData
                }
                setItems(newItems)
                console.log('new items: ' + JSON.stringify(newItems))
                setRequiresSave(false)
                toast.update(toastId, {render: `${res.data['msg']}`, type: "success", isLoading: false, autoClose: 2000})
                forceUpdate()
            })
            .catch(err => {
                if (err.response) {
                  console.log('bad response from server: ' + err.response)
                } else if (err.request) {
                  console.log('no response from server: ' + err)
                } else {
                  console.log(err)
                }
                toast.update(toastId, {render: `Error saving: ${err}`, type: "error", isLoading: false})
            })
    }

    const deleteItem = async (uuid) => {
        let url = base_url + "/" + uuid
        const headers = { 'Content-Type': 'application/json','Authorization': await getAccessToken() }
        const toastId = toast.loading('deleting Site...', toastOptions)
        await axios
            .delete(url, { headers: headers })
            .then(res => {
                console.log(res.data);
                let items_filtered = items.map((x) => x)
                items_filtered = items_filtered.filter(i => i.pk !== uuid)
                setItems(items_filtered)
                toast.update(toastId, {render: `${res.data['msg']}`, type: "success", isLoading: false, autoClose: 2000})
            })
            .catch(err => {
                if (err.response) {
                  console.log('bad response from server: ' + err.response)
                } else if (err.request) {
                  console.log('no response from server: ' + err)
                } else {
                  console.log(err)
                }
                toast.update(toastId, {render: `Error deleting: ${err}`, type: "error", isLoading: false})
            })
    }

    // PrefetchSelectedRow will fetch list details every row you click
    // It should make loading the edit screen faster, but it might add a lot more API calls
    const prefetchSelectedRow = (uuid) => {
        if (selectedRow === uuid) {
            // deselect a row if it's already selected
            setSelectedRow(null)
        }
        else {
            setSelectedRow(uuid)
            let item = getItemByPk(uuid)
            setSelectedItem(item)
            setFormData(item)
            //getDetail(uuid)
        }
    }

    function getItemByPk(pk) {
        return items.find(i => i.pk === pk)
    }

    function getDeployButton(pk, deployed, type) {
        // @TODO change color based on whether the site is deployed or not
        // const color = deployed ? 'primary' : 'warning'
        const color = 'primary'

        if (type === 'onprem') {
            return (
                <td className={isHidden('deploy', headers) ? classes.hide : classes.clickableTableCell}>
                    <RocketLaunchOutlinedIcon color="disabled" />
                </td>
            )
        }
        else {
            return (
                <td className={isHidden('deploy', headers) ? classes.hide : classes.clickableTableCell}>
                    <RocketLaunchOutlinedIcon color={color} onClick={(e) => handleOpenDeploy(e, pk)}/>
                </td>
            )
        }
    }

    const handleOpenColumns = () => {
        setColumnsMode(true);
    };

    const handleCloseColumns = () => {
        setColumnsMode(false);
    };

    const handleOpenAdd = () => {
        setFormData(getBlankFormData())
        setRequiresSave(true)
        setAddMode(true);
        console.log("add new site")
    };

    const handleAdd = (fields) => {
        let addData = addItem(fields)
        setAddMode(false)
    }

    const handleCloseAdd = () => {
        setFormData(getBlankFormData())
        setAddMode(false);
    };

    const handleOpenEdit = (e, uuid) => {
        setSelectedRow(uuid)
        console.log("edit " + selectedRow)
        console.log('uuid = ' + uuid)
        getDetail(base_url, 'site', uuid, setSelectedItem, setEditMode)
        setFormData((prevFormData) => ({...selectedItem, ['pk']: uuid}))
    };

    const handleEdit = (item) => {
        let editData = editItem(formData)
        setEditMode(false)
    }

    const handleCloseEdit = () => {
        setEditMode(false);
    };

    const handleOpenDeploy = (e, uuid) => {
        setSelectedRow(uuid)
        getFileUrls(base_url, 'site', 'cloudformation', uuid, setDeployHref, setDeployCfn);
        setDeployMode(true)
    };

    const handleCloseDeploy = () => {
        setDeployMode(false);
    };

    const handleOpenDelete = (e, uuid) => {
        // stopPropagation seems to block selecting and editing a row in 1 click, so remove it if it doesn't cause any other issues
        //e.stopPropagation()
        setSelectedRow(uuid)
        setDeleteMode(true);
        console.log("delete " + selectedRow)
    };

    const handleDelete = (uuid) => {
        deleteItem(uuid)
        setDeleteMode(false);
    };

    const handleCloseDelete = () => {
        setDeleteMode(false);
    };

    const handleLimit = (event) => {
        setLimit(event.target.value);
        setPages([0])
        setPageIndex(0)
    };

    const handlePreviousPage = () => {
        if (pageIndex > 0) {
            setPageIndex(pageIndex - 1)
        }
    }

    const handleNextPage = () => {
        if (pageIndex < pages.length - 1) {
            setPageIndex(pageIndex + 1)
        }
    }

    const toggleColumn = (event) => {
        for (let i = 0; i < headers.length; i++) {
            if (headers[i]['field'] === event.target.id) {
                headers[i]['hidden'] = !headers[i]['hidden']
            }
        }
        //  ugly but since the checkboxes aren't tied to state, they are tied to column definitions, they don't update
        forceUpdate()
    };

    useEffect(() => {
        getItems()
    }, [pageIndex, limit] )

    return (
        <div className={classes.outer_div} style={{padding: '20px'}}>
            <Paper className={classes.card} variant="outlined" style={{padding: '15px'}}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography variant="h4" >Sites</Typography>
                    <Box>
                        <ViewWeekOutlinedIcon className={classes.spaced} color={"primary"}
                                              onClick={() => handleOpenColumns()}/>
                        <AddOutlinedIcon className={classes.spaced} color={"primary"}
                                         onClick={() => handleOpenAdd()}/>
                    </Box>
                </Stack>

                {/* TABLE */}
                <table className={classes.tableComponent} style={{width: 100 + "%"}} >
                    <thead>
                        <tr className={classes.tableComponent} >
                            {headers.map(header => (
                                <th key={header.field} width={header.width} className={ header.hidden === true ? classes.hide: '' }>{header.headerName}</th>
                                ))}
                        </tr>
                    </thead>
                    <tbody  className={classes.tableComponent} >
                        {items.map(val =>
                            <tr key={val.pk} onClick={() => prefetchSelectedRow(val.pk)}
                                bgcolor={selectedRow === val.pk ? '#242c42' : '#222222'}
                                className={classes.tableComponent}>
                                <td className={isHidden('pk', headers) ? classes.hide : classes.tableCell}>{val.pk}</td>
                                <td className={isHidden('site_name', headers) ? classes.hide : classes.tableCell}>{val.site_name}</td>
                                <td className={isHidden('site_type', headers) ? classes.hide : classes.tableCell}>{val.site_type}</td>
                                <td className={isHidden('aws_account_id', headers) ? classes.hide : classes.tableCell}>{val.aws_account_id}</td>
                                <td className={isHidden('ref_count', headers) ? classes.hide : classes.tableCell}>{val.ref_count}</td>
                                <td className={isHidden('created', headers) ? classes.hide : classes.tableCell}>{formatDate(val.created)}</td>
                                <td className={isHidden('modified', headers) ? classes.hide : classes.tableCell}>{formatDate(val.modified)}</td>
                                <td className={isHidden('edit', headers) ? classes.hide : classes.clickableTableCell}>
                                    <EditOutlinedIcon color="primary" onClick={(e) => handleOpenEdit(e, val.pk)}/>
                                </td>
                                { getDeployButton(val.pk, val.policies_deployed, val.site_type) }
                                <td className={isHidden('delete', headers) ? classes.hide : classes.clickableTableCell}>
                                    <BackspaceOutlinedIcon color="error"
                                                           onClick={(e) => handleOpenDelete(e, val.pk)}/>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <Paper className={classes.itemBar} elevation={1}>
                    <Stack className={classes.pageBar}
                           elevation={0}
                           direction="row"
                        alignItems="center"
                        sx={{ width: 1, height: "5vh" }} >
                        <IconButton onClick={() => getItems()}><RefreshOutlinedIcon className={classes.spaced} /></IconButton>
                        <Typography className={classes.spaced}>Rows per page:</Typography>
                        <Select
                            classes={{ root: classes.whiteColor, icon: classes.whiteColor }}
                            labelId="fetch-limit-select"
                            id="fetch-limit-select"
                            value={limit}
                            label="Limit"
                            onChange={handleLimit}
                        >
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={25}>25</MenuItem>
                          <MenuItem value={50}>50</MenuItem>
                        </Select>
                        <IconButton disabled={pageIndex === 0} onClick={() => handlePreviousPage()}><ChevronLeftOutlinedIcon /></IconButton>
                        <Typography variant={'h6'}>{pageIndex + 1}</Typography>
                        <IconButton disabled={pageIndex >= pages.length - 1} onClick={() => handleNextPage()}><ChevronRightOutlinedIcon /></IconButton>
                    </Stack>
                </Paper>

                {/* DIALOGS */}
                {/* COLUMNS DIALOG */}
                <ColumnsDialog
                    variant={"outlined"}
                    open={columnsMode}
                    onClose={handleCloseColumns}
                    onChange={toggleColumn}
                    headers={headers}
                />

                {/* ADD DIALOG */}
                <SitesDialog
                    open={addMode}
                    onClose={handleCloseAdd}
                    onSave={handleAdd}
                    formData={formData}
                    setFormData={setFormData}
                    requiresSave={requiresSave}
                    setRequiresSave={setRequiresSave}
                />

                {/* EDIT DIALOG */}
                <SitesDialog
                    open={editMode}
                    onClose={handleCloseEdit}
                    onSave={handleEdit}
                    formData={formData}
                    setFormData={setFormData}
                    requiresSave={requiresSave}
                    setRequiresSave={setRequiresSave}
                />

                {/* DEPLOY DIALOG */}
                <DeployDialog
                    type={'site'}
                    open={deployMode}
                    onClose={handleCloseDeploy}
                    href={deployHref}
                    cfn={deployCfn}
                    item={formData}
                />

                {/* DELETE DIALOG */}
                <DeleteDialog
                    open={deleteMode}
                    onClose={handleCloseDelete}
                    onDelete={handleDelete}
                    type={'site'}
                    row={selectedRow}
                    item={selectedItem}
                />
            </Paper>
        </div>
    )
}