const Constants = {

  applicationName: 'RansomStop',

  customerName: 'apitest',
  apiHost: 'apitest-api',
  domainName: 'qa.ransomstop.net',
  version: '24.8.26',
  userPoolId: 'us-east-1_xtLwahzuQ',
  userPoolClientId: '4jg1bf72qsek7ah2opoqlddute',
  region: 'us-east-1',
  invokeUrl: '',

}
export default Constants